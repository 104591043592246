<template>
  <b-modal
    ref="modal"
    :ok-variant="okVariant"
    cancel-variant="outline-secondary"
    :cancel-title="$t('button.close')"
    centered
    no-close-on-backdrop
    :title="title"
    v-model="modalShow"
    @ok="handleOk"
  >
    <p v-if="confirmText">
      {{ confirmText }}
    </p>
    <validation-observer ref="form" v-if="fields.length">
      <n-input :fields="fields" v-model="data"> </n-input>
    </validation-observer>

    <template #modal-ok>
      <b-spinner small label="Spinning" v-if="loading"></b-spinner>
      <span v-else>{{ okText }}</span>
    </template>
  </b-modal>
</template>

<script>
import { BModal, BSpinner, BFormGroup } from "bootstrap-vue";
import NInput from "@/components/NInput";

export default {
  components: {
    BModal,
    BSpinner,
    BFormGroup,

    NInput,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    okText: {
      type: String,
      default: "",
    },
    okVariant: {
      type: String,
      default: "",
    },
    confirmText: {
      type: String,
      default: "",
    },
    fields: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
      modalShow: false,
      data: {},
    };
  },
  methods: {
    show() {
      this.data = {};
      this.loading = false;
      this.modalShow = true;
    },
    hide() {
      this.loading = false;
      this.modalShow = false;
    },
    hideLoading() {
      this.loading = false;
    },
    handleOk(bvModal) {
      bvModal.preventDefault();
      if (this.loading) {
        return;
      }

      if (this.fields.length) {
        this.$refs.form.validate().then((success) => {
          if (success) {
            this.$bvModal
              .msgBoxConfirm(this.confirmText, {
                title: this.$t("alert.areYouSure"),
                size: "sm",
                buttonSize: "sm",
                okVariant: this.okVariant,
                okTitle: this.okText,
                cancelTitle: this.$t("button.cancel"),
                cancelVariant: "outline-secondary",
                hideHeaderClose: false,
                centered: true,
              })
              .then((value) => {
                if (value) {
                  this.submit();
                }
              });
          }
        });
      }
    },
    submit() {
      this.loading = true;
      this.$emit("submit", this.data);
    },
  },
  setup() {},
};
</script>