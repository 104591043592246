<template>
  <div>
    <load-profile></load-profile>
    <b-row class="mb-1" v-if="data.id">
      <b-col class="text-right">
        <b-button-group size="sm">
          <b-button
            variant="secondary"
            :disabled="!data.prevId || loading"
            @click="goTo(data.prevId)"
          >
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
          <b-button
            variant="secondary"
            :disabled="!data.nextId || loading"
            @click="goTo(data.nextId)"
          >
            <feather-icon icon="ChevronRightIcon" />
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <b-row>
          <b-col lg="8" xl="9">
            <b-card>
              <b-row class="mb-1">
                <b-col>
                  <b-skeleton
                    type="avatar"
                    size="60px"
                    class="mx-auto"
                  ></b-skeleton>
                  <b-skeleton width="150px" class="mx-auto mt-1"></b-skeleton>
                  <b-skeleton width="50px" class="mx-auto"></b-skeleton>
                </b-col>
              </b-row>
              <b-skeleton-table
                hide-header
                :columns="6"
                :rows="3"
                :table-props="{ bordered: false, striped: true }"
              >
              </b-skeleton-table>

              <b-skeleton-table
                hide-header
                :columns="6"
                :rows="3"
                :table-props="{ bordered: false, striped: true }"
              >
              </b-skeleton-table>
            </b-card>
          </b-col>
          <b-col>
            <b-card>
              <b-skeleton
                width="100%"
                v-for="i in 10"
                :key="`loading-${i}`"
              ></b-skeleton>
            </b-card>
          </b-col>
        </b-row>
      </template>
      <div v-if="data.id">
        <b-row>
          <b-col lg="8" xl="9">
            <b-card>
              <b-row class="mb-2">
                <b-col class="text-center">
                  <b-avatar
                    size="60"
                    class="avatar-border mb-1"
                    variant="light-primary"
                    :text="avatarText(data.salespersonName)"
                    :src="data.salesperson ? data.salesperson.image : ''"
                  >
                    <feather-icon
                      v-if="!data.salespersonName"
                      icon="UserIcon"
                      size="32"
                    />
                  </b-avatar>
                  <h3 class="mb-0">
                    {{ data.salespersonName }}
                  </h3>
                  <small class="d-block text-primary">
                    {{ data.salespersonIdCard }}
                  </small>
                </b-col>
              </b-row>
              <div class="text-right" v-if="data.fileUrl">
                <b-button
                  variant="outline-primary"
                  class="mb-1"
                  size="sm"
                  @click="viewFile"
                >
                  <i class="las la-image"></i>
                </b-button>
              </div>
              <b-table-simple
                responsive
                borderless
                striped
                class="table-information mb-0"
              >
                <b-tbody>
                  <b-tr>
                    <b-td>
                      {{ $t("field.position") }}
                    </b-td>
                    <b-td>
                      {{ trans(data, "positionName", $i18n.locale) }}
                    </b-td>
                    <b-td>
                      {{ $t("field.department") }}
                    </b-td>
                    <b-td>
                      {{ trans(data, "departmentName", $i18n.locale) }}
                    </b-td>
                    <b-td>
                      {{ $t("field.leaveType") }}
                    </b-td>
                    <b-td>
                      {{ trans(data, "leaveTypeName", $i18n.locale) }}
                    </b-td>
                    <b-td>
                      {{ $t("field.fromDate") }}
                    </b-td>
                    <b-td>
                      {{ data.fromDate | formatDate("LL") }}
                    </b-td>
                    <b-td>
                      {{ $t("field.toDate") }}
                    </b-td>
                    <b-td>
                      {{ data.toDate | formatDate("LL") }}
                    </b-td>
                    <b-td>
                      {{ $t("field.quantity") }}
                    </b-td>
                    <b-td>
                      {{ data.quantity }} {{ $tc("time.day", data.quantity) }}
                    </b-td>
                    <b-td>
                      {{ $t("field.reason") }}
                    </b-td>
                    <b-td>
                      {{ data.reason }}
                    </b-td>
                    <b-td>
                      {{ $t("field.backupPerson") }}
                    </b-td>
                    <b-td>
                      {{ data.backupPerson }}
                    </b-td>
                    <b-td>
                      {{ $t("field.state") }}
                    </b-td>
                    <b-td>
                      <b-badge
                        pill
                        :class="{
                          'badge-light-danger': data.state == 0,
                          'badge-light-success': data.state == 5,
                          'badge-light-warning': [1, 2, 3, 4].includes(
                            data.state
                          ),
                        }"
                      >
                        {{ $t(`leaveState.${data.state}`) }}
                      </b-badge>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-card>
            <b-card>
              <h5>{{ $t("field.detail") }}</h5>
              <b-table-simple responsive borderless striped class="mb-0">
                <b-tbody>
                  <b-tr
                    v-for="(item, idx) in data.details"
                    :key="`detail-${idx}`"
                  >
                    <b-th> {{ $t("field.date") }}: </b-th>
                    <b-td>
                      {{ item.date | formatDate("LL") }}
                      {{ item.fromDate | formatDate("LT") }} -
                      {{ item.toDate | formatDate("LT") }} ({{
                        trans(item, "scheduleName", $i18n.locale)
                      }})
                    </b-td>
                    <b-th> {{ $t("field.quantity") }}: </b-th>
                    <b-td>
                      {{ item.quantity }} {{ $tc("field.day", item.quantity) }}
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-card>
          </b-col>
          <b-col>
            <b-card v-if="canReviewRequest()">
              <b-button block variant="primary" @click="openApproveModal">
                {{ $t("button.approve") }}
              </b-button>
              <b-button block variant="danger" @click="openRejectModal">
                {{ $t("button.reject") }}
              </b-button>
            </b-card>
            <b-card>
              <app-timeline>
                <app-timeline-item
                  v-for="(log, idx) in logs"
                  :key="`log-${idx}`"
                  :date="log.date"
                  :name="log.name"
                  :idCard="log.idCard"
                  :remark="log.remark"
                  :state="log.state"
                  :variant="log.variant"
                />
              </app-timeline>
            </b-card>
          </b-col>
        </b-row>

        <confirm-modal
          ref="approveModal"
          :title="$t('alert.areYouSure')"
          :ok-text="$t('button.approve')"
          ok-variant="primary"
          :fields="approveFields"
          :confirm-text="$t('alert.approveLeave')"
          @submit="approve"
        />
        <confirm-modal
          ref="rejectModal"
          :title="$t('alert.areYouSure')"
          :ok-text="$t('button.reject')"
          ok-variant="danger"
          :fields="fields"
          :confirm-text="$t('alert.rejectLeave')"
          @submit="reject"
        />
      </div>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTableSimple,
  BTbody,
  BTr,
  BTh,
  BTd,
  BAvatar,
  BBadge,
  BButtonGroup,
  BButton,
  BLink,
  BSkeletonWrapper,
  BSkeleton,
  BSkeletonTable,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import { avatarText } from "@core/utils/filter";
import AppTimeline from "@/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@/components/app-timeline/AppTimelineItem.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import FormInput from "./formInput";
import ApproveFormInput from "./approveFormInput";

const LeaveRequestRepository = Repository.get("leaveRequest");

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BAvatar,
    BBadge,
    BButtonGroup,
    BButton,
    BLink,
    BSkeletonWrapper,
    BSkeleton,
    BSkeletonTable,

    AppTimeline,
    AppTimelineItem,
    ConfirmModal,
  },
  directives: {
    Ripple,
  },
  computed: {
    userData: function () {
      return this.$store.state.profile?.username
        ? this.$store.state.profile
        : JSON.parse(localStorage.getItem("userData"));
    },
  },
  data() {
    return {
      loading: true,
      data: {},
      logs: [],

      avatarText,
    };
  },
  mounted() {
    this.show();
  },
  methods: {
    viewFile() {
      if (this.data.fileUrl) {
        this.previewImage(this.data.fileUrl);
      }
    },
    openApproveModal() {
      this.$refs.approveModal.show();
    },
    openRejectModal() {
      this.$refs.rejectModal.show();
    },
    approve(data) {
      LeaveRequestRepository.approve(this.$route.params.id, data)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.data = {
              ...data,
            };
            this.mapLogs();
          }
          this.$refs.approveModal.hide();
        })
        .catch(() => this.$refs.approveModal.hideLoading());
    },
    reject(data) {
      LeaveRequestRepository.reject(this.$route.params.id, data)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.data = {
              ...data,
            };
            this.mapLogs();
          }
          this.$refs.rejectModal.hide();
        })
        .catch(() => this.$refs.rejectModal.hideLoading());
    },
    canReviewRequest() {
      return (
        (this.data.state == 3 && this.data.leaveVerifyId == this.userData.id) ||
        (this.data.state == 4 &&
          this.data.finalLeaveApprovalId == this.userData.id)
      );
    },
    show() {
      this.loading = true;
      LeaveRequestRepository.show(this.$route.params.id)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.data = {
              ...data,
            };
            this.mapLogs();
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    mapLogs() {
      let logs = [];
      this.data.logs.forEach((element, idx) => {
        let state = "field.submit";
        let variant = "success";
        if ([1, 2, 3, 4, 5].includes(element.state)) {
          if (idx > 0) {
            state = "field.approved";
            variant = "success";
          }
        } else if (element.state == 0) {
          state = "field.rejected";
          variant = "danger";
        } else if (element.state == 6) {
          state = "field.cancelled";
          variant = "danger";
        }
        logs.push({
          name: element.name,
          idCard: element.idCard,
          date: element.createdAt,
          remark: element.remark,
          state: state,
          variant: variant,
        });
      });
      if (this.data.state != 0 && this.data.state != 6) {
        let log = {
          date: null,
          remark: null,
          state: "field.pendingApproval",
          variant: "secondary",
        };
        if (this.data.state < 2 && this.data.firstLeaveApprovalId) {
          logs.push({
            ...log,
            name: this.data.firstLeaveApproval?.name,
            idCard: this.data.firstLeaveApproval?.idCard,
          });
        }
        if (this.data.state < 3 && this.data.secondLeaveApprovalId) {
          logs.push({
            ...log,
            name: this.data.secondLeaveApproval?.name,
            idCard: this.data.secondLeaveApproval?.idCard,
          });
        }
        if (this.data.state < 4 && this.data.leaveVerifyId) {
          logs.push({
            ...log,
            name: this.data.leaveVerify?.name,
            idCard: null,
          });
        }
        if (this.data.state < 5 && this.data.finalLeaveApprovalId) {
          logs.push({
            ...log,
            name: this.data.finalLeaveApproval?.name,
            idCard: null,
          });
        }
      }
      this.logs = [...logs];
    },
    goTo(id) {
      if (!id) {
        return;
      }
      this.$router.replace({
        name: `view-${this.route}`,
        params: {
          id: id,
        },
        query: {
          tabIndex: this.tabIndex,
        },
      });
      this.show();
    },
    back() {
      this.$router.push({
        name: `list-${this.route}`,
      });
    },
  },
  setup() {
    const fields = FormInput;
    const approveFields = ApproveFormInput;
    const resource = "leave-request";
    const route = "leave-request";

    return { fields, approveFields, resource, route };
  },
};
</script>